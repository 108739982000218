<template>
	<div :style="bgLogin" class="login" >

		<!-- <el-container>
			<el-main style="width: 100%;"> -->
		<div class="logincon">
			<div class="loginLeft">
				<img :src="logo" class="leftImg" />
			</div>
			<div class="login-form">
				<div class="logintype" style="text-align: center;">
					<h3 @click="showLogin11" style="font-size: 22px;" :class="{login1:showLogin1}">
						账号登录&nbsp;<span style="color:#188B9D ;">|</span>&nbsp;</h3>
					<h3 @click="showLogin22" style="font-size: 22px;" :class="{login2:showLogin2}">短信登陆
					</h3>
				</div>
				<div>

				</div>

				<el-form status-icon style="width: 82%;margin: 0px auto;padding-top: 10px;" :model="ruleForm"
					:rules="rules" ref="ruleForm">
					<!-- 账号密码 -->
					<div class="phoneandpwd" v-if="showLogin1">
						<el-form-item prop="phone">
							<img alt="user" class="defaultUser" src="../assets/login/user.png" width="20" height="20" />
							<el-input @blur="phoneBlur" @focus="phoneFocus" class="userName" v-model="ruleForm.phone"
								placeholder="手机号"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<img src="../assets/login/pwd.png" width="15" height="15" class="defaultPwd" />
							<el-input type="password" @blur="pwdBlur" @focus="pwdFocus" class="userPwd"
								v-model="ruleForm.password" placeholder="登录密码"></el-input>
						</el-form-item>
						<el-form-item prop="valicode">
							<img src="../assets/login/validate.png" width="15" height="15" class="defaultValidate" />
							<div style="display: flex;">
								<el-input style="width: 52%;margin-right: 1.9125rem;" @blur="validateBlur"
									@focus="validateFocus" class="userPwd" v-model="ruleForm.valicode"
									placeholder="验证码">
								</el-input>
								<ValidateCode></ValidateCode>
							</div>
						</el-form-item>
					</div>
					<!-- 手机号验证码 -->
					<div class="phonecode" v-if="showLogin2">
						<el-form-item prop="phone">
							<img alt="user" class="defaultUser" src="../assets/login/user.png" width="20" height="20" />
							<el-input @blur="phoneBlur" @focus="phoneFocus" class="userName" v-model="ruleForm.phone"
								placeholder="手机号"></el-input>
						</el-form-item>
						<el-form-item prop="code" style="width: 100%;">
							<img src="../assets/login/validate.png" width="15" height="15" class="defaultCode" />
							<div style="display: flex;">
								<el-input style="width: 65%;margin-right: 0.425rem;" type="code" @blur="codeBlur"
									@focus="codeFocus" class="userPwd" v-model="ruleForm.code" placeholder="验证码">
								</el-input>
								<el-button class="code1" id="code1"
									style="width: 34%;border:2px solid #0D8EA4;color: #0D8EA4;font-size: 10px;"
									@click="getCode" :disabled="disabled">获取验证码</el-button>
							</div>
						</el-form-item>
					</div>
					<!-- 勾选协议 -->
					<el-form-item style="height: 30px;">
						<el-checkbox style="float: left;" v-model="checked" @change="xieyi">
							<span style="font-size: 16px !important;">接受山东泰克莱恩</span><a href="./zhengcetiaokuan.html" target="_blank"
								style="color: #188B9D;font-size: 16px !important;">&lt;&lt;使用条款及隐私政策&gt;&gt;</a>
						</el-checkbox>
					</el-form-item>
					<el-form-item style="height: 30px;" class="btn">
						<el-button :disabled="btnIsDisabled"
							style="width: 98%;background:linear-gradient(to right,#559AB8,#4C9BA2) ;border-radius: 0.625rem;font-size: 19px !important;" type="primary"
							@click="submitForm('ruleForm')">登 录
						</el-button>
					</el-form-item>
					<el-form-item class="reg">
						<span style="color: #000000;font-size: 16px !important;" class="noAccount">还没有账号?<a href="#"
								@click="goRegister" style="font-size: 16px !important;">立即注册</a></span>&nbsp;&nbsp;&nbsp;
						<a style="font-size: 16px !important;" class="forgetpwd" href="#" @click="goModifyPwd">忘记登录密码?</a>
					</el-form-item>
				</el-form>

			</div>
		</div>
		<!-- </el-main> -->

	</div>
</template>

<script>
	import ActiveUser from '../assets/login/user1.png'
	import DefaultUser from '../assets/login/user.png'
	import ActivePwd from '../assets/login/pwd1.png'
	import DefaultPwd from '../assets/login/pwd.png'
	import ActiveValidate from '../assets/login/validate1.png'
	import DefaultValidate from '../assets/login/validate.png'
	import ActiveCode from '../assets/login/validate1.png'
	import DefaultCode from '../assets/login/validate.png'

	import ValidateCode from '../components/validatecode.vue'
	export default {
		data() {
			var checkPhone = (rule, value, callback) => {
				  
			        if (!value) {
			          return callback(new Error('手机号不能为空'));
			        }
			        setTimeout(() => {
						 
						  this.$axios.get("/checkPhone",{
						  	params:{
						  		phone:value
						  	}
						  }).then(res=>{
							  console.log(res.data);
						  	if (res.data.code==200&&res.data.data.exist=="no") {
						  	  callback(new Error('手机号不存在'));
						  	} else {
						  	  callback();
						  	}
						  }).catch(err=>{
						  	console.log(err);
						  });
			            
			          
			        }, 500);
			      };
			var checkValidate = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('验证码不能为空'));
				}
				let code = this.$store.state.validateCode;
				console.log(code);
				if (code != value) {
					callback(new Error('验证码输入有误'));
				} else {
					callback();
				}
			}
			return {
				logo:'',
				bgLogin:'',
				ruleForm: {
					phone: '',
					password: '',
					code: '',
					valicode: ''
				},
				activeUser: ActiveUser,
				defaultUser: DefaultUser,
				activePwd: ActivePwd,
				defaultPwd: DefaultPwd,
				activeValidate: ActiveValidate,
				defaultValidate: DefaultValidate,
				activeCode: ActiveCode,
				defaultCode: DefaultCode,
				checked: false,
				dialogTableVisible: false,
				btnIsDisabled: true,
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '长度为11个字符',
							trigger: 'blur'
						},
						 { validator: checkPhone, trigger: 'blur' }
						/* {
							pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
							message: '手机号格式有误'
						} */
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						/* {
							pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/,
							message: "必须包含大小写字母和数字的组合长度在8-10之间"
						} */
					],
					valicode: [{
						validator: checkValidate,
						trigger: "blur"
					}],
					code:[{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				showLogin1: true,
				showLogin2: false,
				disabled: false, //控制按钮的是否禁用
				resCode: ''
			};
		},
		components: {
			ValidateCode
		},
		methods: {
			//切换登录方式
			showLogin11() { //手机号,密码
				this.showLogin1 = true;
				this.showLogin2 = false;
				this.$refs['ruleForm'].resetFields();
			},
			showLogin22() { //手机号验证码
				this.showLogin1 = false;
				this.showLogin2 = true;
				this.$refs['ruleForm'].resetFields();
			},
			//提交表单
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) { //验证通过
						//需要使用axios提交数据到后台
						if (this.showLogin1) { //手机密码登录
							this.$axios.post("doctorLogin", JSON.stringify(this.ruleForm), {
								"headers": {
									'Content-Type': 'application/json'
								}
							}).then(res => {
								console.log(res.data);
								if (res.data.code == 407) {
									this.$message.error("密码输入错误");
								} else if (res.data.code == 402) {
									this.$message.error("手机号错误");
								} else if(res.data.code==403||res.data.code==401){
									this.$message.error("验证码错误");
								}else if (res.data.code == 400) {
									this.$message.error("该账户已被注销");
								}
								else { //登录成功
									sessionStorage.setItem("loginInfo", JSON.stringify(res.data.data
										.doctorInfo));
									console.log(res.data.data
										.token)
									sessionStorage.setItem("token", JSON.stringify(res.data.data
										.token));
									// this.$store.commit('setDoctorId', res.data.data
									// 	.doctorInfo.id)
									sessionStorage.setItem("doctorId",res.data.data
										.doctorInfo.id)
									this.$router.push("/selecttype");
								}
							}).catch(err => {
								console.log(err);
								this.$message.error("请联系管理员");
							});
							// this.$axios.post("http://39.105.161.12:8088/testToken").then(res=>{
							// 	console.log(res);
							// }).catch(err=>{
							// 	this.$message.error("请联系管理员");
							// })
						} else { //手机验证码登录
							let obj = {
								phone: this.ruleForm.phone,
								code: this.ruleForm.code
							}
							this.$axios.post("doctorCodeLogin", JSON.stringify(obj), {
								headers: {
									"Content-type": "application/json"
								}
							}).then(res => {
								console.log(res.data.code);
								if (res.data.code == 403) {
									this.$message.error("验证码输入错误");
								} else if (res.data.code == 402) {
									this.$message.error("手机号错误");
								} else { //登录成功
									console.log(res.data.code);
									if (res.data.code == 200) {
										//this.$message.success("登录成功");
										
									}
									sessionStorage.setItem("loginInfo", JSON.stringify(res.data.data
										.doctorInfo));
									
									sessionStorage.setItem("token", JSON.stringify(res.data.data
										.token));
									
									sessionStorage.setItem("doctorId",res.data.data
										.doctorInfo.id)
									this.$router.push("/selecttype");
								}
							}).catch(err => {
								this.$message({
									message: "请联系管理员",
									type: "error",
									duration: 900
								})
							});

						}

					} else { //验证未通过
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//获取验证码
			getCode() {
				if (this.ruleForm.phone == '') {
					this.$message({
						message: "请输入手机号",
						type: "warning",
						duration: 900
					});
				} else {
					let _this = this;
					let countdown = 61;
					let clear;
					//按钮是禁用状态
					this.disabled = true;
					this.$axios.get("getLoginCode", {
						params: {
							phone: this.ruleForm.phone
						}
					}).then(res => {
						console.log(res.data.data.resCode);
						this.resCode = res.data.data.resCode;
					}).catch(err => {
						console.log(err);
						this.$message({
							message: "请联系管理员",
							type: "error",
							duration: 900
						})
					});
					clear = setInterval(function() {
						countdown--;
						if (countdown == 0) {
							countdown = 61;
							_this.disabled = false;
							clearInterval(clear);
							document.getElementById("code1").innerHTML = "重新发送验证码";
						} else {
							document.getElementById("code1").innerHTML = countdown + "秒";
						}

					}, 1000)
				}

			},
			//手机号码获取焦点
			phoneFocus() {
				/* $(".defaultUser").attr("src",require('../assets/login/user1.png')); */
				$(".defaultUser").attr("src", this.activeUser);
			},
			//手机号码失去焦点
			phoneBlur() {
				$(".defaultUser").attr("src", this.defaultUser);
			},
			//密码获取焦点
			pwdFocus() {
				/* $(".defaultUser").attr("src",require('../assets/login/user1.png')); */
				$(".defaultPwd").attr("src", this.activePwd);
			},
			//密码失去焦点
			pwdBlur() {
				$(".defaultPwd").attr("src", this.defaultPwd);
			},
			//验证码失去焦点
			validateBlur() {
				$(".defaultValidate").attr("src", this.defaultValidate);
			},
			//验证码获取焦点
			validateFocus() {
				$(".defaultValidate").attr("src", this.activeValidate);
			},
			//短信验证码失去焦点
			codeBlur() {
				$(".defaultCode").attr("src", this.defaultCode);
			},
			//短信验证码获取焦点
			codeFocus() {
				$(".defaultCode").attr("src", this.activeCode);
			},
			//去注册页面
			goRegister() {
				this.$router.push('/register');
			},
			//忘记登录密码
			goModifyPwd() {
				this.$router.push('/modifypwd');
			},
			//勾选协议时触发的事件
			xieyi() {
				console.log(this.checked);
				if (this.checked) {
					console.log(1);
					this.btnIsDisabled = false;
				} else {
					this.btnIsDisabled = true;
				}
			},
			
			

		}
	    ,
		mounted:function(){
			//1.动态获取登录左边图片
	    	this.$axios.get("/getImgUrl", {
	    		params: {
	    			type: "logo"
	    		}
	    	}).then(res => {
	    		this.logo=res.data.data.url;
	    	}).catch(err => {
	    		console.log(err);
	    	});
			//2.动态获取登录背景图片
			this.$axios.get("/getImgUrl", {
				params: {
					type: "bgLogin"
				}
			}).then(res => {
				this.bgLogin="background-image:url("+res.data.data.url+")";
			}).catch(err => {
				console.log(err);
			});
	    }
	}
</script>

<style scoped="scoped">
	.login {
		height: 100%;
		width: 100%;
		background-color: whitesmoke;
		/* background-image: url(../assets/denglu/denglubeijing.jpg); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.el-container {
		width: 100%;
		height: 100%;
	}

	.el-main {
		height: 95%;
		width: 100%;
		position: relative;

	}



	.logincon {
		height: 508.8px;
		width: 824px;
		background-color: rgba(255, 255, 255, 0.4);
		border-radius: 0.9375rem;
		margin: 0px auto;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	.code1 {
		width: 36% !important;
		padding-left: 0.4rem !important;

	}

	.el-checkbox__label a,
	.el-checkbox__label span {
		font-size: 13px !important;
	}
.reg{
	position: relative;
	margin-top: -5px;
}
	.reg a,
	.reg span {
		font-size: 13px !important;
	}





	.loginLeft {
		flex: 1;
		color: #096898;
		font-size: 1.875rem;
		font-weight: bolder;
		/* background-image: url(../assets/denglu/dlright.jpg);
		background-repeat: no-repeat;
		background-size: 100% 100%;*/
		border-radius: 15px 0px 0px 15px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-footer {
		text-align: center;
		height: 5%;
		width: 100%;
		position: fixed;
		bottom: 0px;
		font-size: 0.875rem;
		color: black;
		vertical-align: middle;
		padding-top: 1%;
	}

	.login-form {
		/* padding: 0.625rem; */
		/* height: 100%; */
		margin: 0px auto;
		background-image: linear-gradient(to bottom, white, #FFFFFF);
		/* border-radius: 0.5125rem; */
		box-shadow: #CCCCCC;
		/* margin-top: 4%; */
		flex: 1;
		border-radius: 0px 15px 15px 0px;
		width: ;
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
	}

	.el-form {
		/* width: 300px !important;
		display: flex !important;
		flex-direction: column !important;
		padding-top: 0px !important; */
		 height: 320px;
	}

	.el-form-item {
		/* margin: 7px; */
		height: 40px;
	}

	.btn {
		/* position: relative;
		top: -0.625rem; */
	}

	.defaultUser {
		position: absolute;
		left: 0.625rem;
		top: 0.625rem;
		z-index: 100;
	}

	.defaultPwd {
		position: absolute;
		left: 0.625rem;
		top: 0.825rem;
		z-index: 100;
	}

	.defaultValidate {
		position: absolute;
		left: 0.625rem;
		top: 0.825rem;
		z-index: 100;
	}

	.defaultCode {
		position: absolute;
		left: 0.625rem;
		top: 0.825rem;
		z-index: 100;
	}

	.noAccount {
		/* color: #2B9BB3; */
		color: rgb(0, 47, 167);
	}

	.noAccount a {
		color: #0D8EA4;
		text-decoration: underline;
		font-weight: bold;
	}

	.forgetpwd {
		color: #0D8EA4;
	}

	.el-button--primary {
		background-color: rgb(0, 47, 167);
		border: none;
		color: white;
		font-weight: bold;
		font-size: 1rem;
	}

	.logintype {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	h1 {
		font-size: 1.05rem;
		font-weight: lighter;

	}

	.login1,
	.login2 {
		color: #188B9D;
		cursor: pointer;
		font-size: 1.2625rem;
	}

	/* 获取验证码 */
	.code1 {
		/* background-image: linear-gradient(to right,white,#36DBD8); */
		border: none;
		text-align: center;
		padding-left: 0.625rem;
		height: 2.5rem !important;
		position: relative;
		top: -0.125rem;
		border-radius: 0.75rem !important;
	}

	.errmess {
		color: red;
		font-size: 0.875rem;
	}

	h3 {
		margin: 0rem;
		/* margin-top: 1.8125rem;
		padding-bottom: 0.625rem; */
		color: darkgray;
		font-size: 16px;
		font-weight: normal;
		font-size: 1.5625rem;
	}

	.leftImg {
		width: 100%;
		height: 100%;
		border-radius: 15px 0px 0px 15px;

		border-left: 1px solid #64B2BE;
	}
</style>
